:-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  border-radius: 100px;
  box-shadow: inset 2px 2px 5px 0 rgba(#888888, 0.5);
}

.site-layout-background {
  height: 50px;
  line-height: 50px;
}
.default-layout-sider .ant-menu-item-selected::after {
  width: 2px;
  background: #256532;
}
.default-layout-sider .ant-menu-title-content {
  text-transform: capitalize;
  color: #256532;
  font-weight: bold;
}
.site-layout-background {
  background: #256532;
  color: #fff;
  height: 60px;
  line-height: 60px;
}
.default-layout-sider .ant-menu-item {
  color: #256532;
}
.card-filter {
  margin-bottom: 20px;
}
.card-filter .ant-form-item {
  margin-bottom: 0;
}
.login-page {
  position: relative;
  background-color: #256532;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
.login-page-copyright {
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
}

